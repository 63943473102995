import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { Media } from "@ryerson/frontend.layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";

const MetalExpertsContainer = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.lightGray};
		`;
	}}
`;

const MetalExpertsInternalContainer = styled.div`
	padding-top: 20px;
	padding-right: max(20px, env(safe-area-inset-right));
	padding-bottom: 20px;
	padding-left: max(20px, env(safe-area-inset-left));
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding-bottom: 0px;
				padding-right: 0px;
				padding-left: 0px;
			}
		`;
	}}
`;

const MetalExpertsInternalButtonsContainer = styled.div`
	padding-top: 20px;
	padding-right: max(20px, env(safe-area-inset-right));
	padding-left: max(20px, env(safe-area-inset-left));
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding-bottom: 0px;
				padding-right: 0px;
				padding-left: 0px;
			}
		`;
	}}
`;

const ContactDetailsImgWrapper = styled.span`
	display: inline-block;
	margin-right: 20px;
	vertical-align: middle;
	width: 100px;
	overflow: hidden;
	height: 100px;
	border-radius: 100px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-right: 15px;

				width: 75px;
				height: 75px;
				border-radius: 75px;
			}
		`;
	}}
`;

const ContactDetailsTextWrapper = styled.span`
	display: inline-block;
	margin-right: 20px;
	vertical-align: middle;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-right: 0px;
			}
		`;
	}}
`;

const ButtonWrapper = styled.span`
	padding: 20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				padding: 20px 40px 20px 0px;
			}
		`;
	}}
`;

type ContactUsExpertStaticContent = {
	yourMetalExpert: string;
	messageMe: string;
	chatWithALiveRep: string;
};

export const StaticContent: LanguageContent<ContactUsExpertStaticContent> = {
	en: {
		yourMetalExpert: "Your Metals Professional",
		messageMe: "Message Me",
		chatWithALiveRep: "Chat with a Live Rep",
	},
	fr: {
		yourMetalExpert: "Votre expert en métaux",
		messageMe: "Envoie moi un message",
		chatWithALiveRep: "Discutez avec un représentant en direct",
	},
	es: {
		yourMetalExpert: "Tu experto en metales",
		messageMe: "Mensajeame",
		chatWithALiveRep: "Chatea con un representante en vivo",
	},
};

const Divider = styled.hr`
	opacity: 0.1;
	max-width: 1160px;
	width: 100%;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-top: 1px solid ${theme.colors.secondary.darkBlue};
		`;
	}}
`;

export type ContactUsExpertDynamicContent = {
	contactName?: string;
	contactEmail?: string;
	contactPhone?: string;
	contactTitle?: string;
	contactImageUrl: string;
};

export type ContactUsExpertProps = {
	content: ContactUsExpertDynamicContent;
};

export const ContactUsExpert: React.FC<ContactUsExpertProps> = ({ content }) => {
	const defaultPortrait =
		process.env.GATSBY_COMPANY === "southernToolSteel"
			? "/static-assets/images/metal-experts/isrDefaultSTS.png"
			: "/static-assets/images/metal-experts/isrDefault.png";
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const imageStyle = css`
		object-fit: cover;
		object-position: center center;
		height: 100%;
		width: 100%;
	`;

	const [image, setImage] = React.useState(content.contactImageUrl);
	React.useEffect(() => {
		setImage(content.contactImageUrl);
	}, [content.contactImageUrl]);

	return (
		<React.Fragment>
			<Media greaterThanOrEqual="lg">
				<Typography
					variant="p"
					size="lg"
					color={theme.colors.primary.header}
					className="header"
				>
					{StaticContent[language].yourMetalExpert}
				</Typography>
			</Media>
			<Divider theme={theme} />
			<MetalExpertsContainer theme={theme}>
				{content.contactName && content.contactName.length > 0 && (
					<MetalExpertsInternalContainer theme={theme}>
						<ContactDetailsImgWrapper theme={theme}>
							<img
								src={image}
								css={imageStyle}
								onError={() => {
									if (image !== defaultPortrait) {
										setImage(defaultPortrait);
									}
								}}
							></img>
						</ContactDetailsImgWrapper>
						<ContactDetailsTextWrapper theme={theme}>
							<Typography
								variant="p"
								weight="bold"
								color={theme.colors.primary.secondaryBrand}
								size={"md"}
								css={css`
									margin: 0px;
								`}
							>
								{content.contactName ?? ""}
							</Typography>
							{content.contactTitle && (
								<React.Fragment>
									<Media greaterThanOrEqual="lg">
										<Typography variant="div" size="md" type="secondary">
											{content.contactTitle}
										</Typography>
									</Media>
									<Media lessThan="lg">
										<Typography variant="div" size="md" type="secondary">
											{content.contactTitle}
										</Typography>
									</Media>
								</React.Fragment>
							)}
							{content.contactPhone && (
								<React.Fragment>
									<Media greaterThanOrEqual="lg">
										<Typography variant="div" size="lg" type="secondary">
											{content.contactPhone}
										</Typography>
									</Media>
									<Media lessThan="lg">
										<Typography variant="div" size="md" type="secondary">
											{content.contactPhone}
										</Typography>
									</Media>
								</React.Fragment>
							)}
						</ContactDetailsTextWrapper>
					</MetalExpertsInternalContainer>
				)}

				<MetalExpertsInternalButtonsContainer theme={theme}>
					{content.contactEmail && (
						<Link gatsby={false} to={"mailto:" + content.contactEmail}>
							<ButtonWrapper theme={theme}>
								<Button
									label={StaticContent[language].messageMe}
									onClick={() => {}}
									shape="rounded"
									type="secondary"
									paddingType="relaxed"
									fullwidth={true}
								/>
							</ButtonWrapper>
						</Link>
					)}
					<ButtonWrapper theme={theme}>
						<Button
							label={StaticContent[language].chatWithALiveRep}
							onClick={() => {
								if (window) {
									if (window && (window as any).LiveChatWidget)
										(window as any).LiveChatWidget.call("maximize");
								}
							}}
							shape="rounded"
							type="secondary"
							paddingType="relaxed"
							fullwidth={true}
						/>
					</ButtonWrapper>
				</MetalExpertsInternalButtonsContainer>
			</MetalExpertsContainer>
		</React.Fragment>
	);
};
